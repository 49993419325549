<template>
  <div class="zchjs">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item >企业服务</el-breadcrumb-item>
        <el-breadcrumb-item>通知公告</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="zhuti">
        <div class="news" v-for="(ar,index) in arr" :key="index" @click="gotoxq(ar)">
          <div class="title"><i class="yuan"></i>{{ar.title}}</div>
          <div class="flex di">
            <div class="flex-1" v-if="ar.source">来源:{{ar.source}}</div>
            <div class="artime">发布时间:{{ar.createTime}}</div>
          </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import gxjyheader from "../gxjy/gxjyheader";
import {noticeselect} from "../../request/moudle/zcfw";
export default {
  data(){
    return {
      page:{
        current:1,
        size:10,
        total:0
      },
      arr:[],
    }
  },
  created() {
    this.loadlist()
  },
  methods:{
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        type:this.$route.query.type
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zcfw.noticeselect(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    gotoxq(ar){
      this.$router.push({
        path:'/gonggaoxq',
        query:{
          id:ar.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zchjs{
  background: #F5F5F5;
}
.news{
  background: #fff;
  padding: 20px;
  color: #3B3E45;
  margin-bottom: 20px;
  cursor: pointer;
  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .yuan{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #A5ACB9;
    margin-right: 15px;
  }
  .di{
    font-size: 14px;
    padding-left: 27px;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 140px;
  background: url("../../assets/gxjy/gxjy.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  .left{
    width: 535px;
    margin-right: 25px;
    color: #fff;
    .cont{
      height: 249px;
      background: #334C66;
    }
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
  .right{
    color: #fff;
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
    .news{
      background: #fff;
      color: #4C4C4C ;
      font-size: 14px;
      padding: 5px 16px;
      border-bottom: 1px dashed  #4C4C4C;
      .type{
        width: 50px;
      }
      .time{
        width: 120px;
      }
    }
    .news:nth-last-child(1){
      border-bottom: none;
    }
  }
  .right{

  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}
.title1{
  width: 1200px;
  margin:30px auto;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#000000 ;
  span{
    border-top: 1px solid #000000;
    margin: 0 15px;
    margin-top: 14px;
  }
}
</style>